.editBpmnList {
    background: #fff3d8 ;
    color: #f4772e;
    width: 40px !important;
    height: 40px !important;
    padding: 9px;
    border-radius: 8px
}

.deleteBpmnList{
    background: #ffd7db ;
    color: #f83245; 
    width: 40px !important;
    height: 40px !important;
    padding: 9px;
    border-radius: 8px
}